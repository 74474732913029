import React, { Component } from "react";
import Account from "../account";
import "./style.css";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import Loader from "../../components/UI/Loader";
import ErrorAlert from "../../components/errorAlert";
import { TextInput } from "../../components/UI/TextInput";
import { SelectBox } from "../../components/UI/SelectBox";
import { Button } from "../../components/UI/Button";
import { MyDatePicker } from "../../components/UI/MyDatePicker";
import { checkValidationErrors } from "./form-validation";
import { getErrorMessage } from "../../helpers";
import {
  santizeSelectedResult,
  sortItemsByAlphabetical,
  constructSelectedItemApiParams,
  extractItems,
} from "./utils";
import PhoneInput from "react-phone-input-2";
import {navigateToAccountPage} from "../login/utils";
import {withTranslation} from "react-i18next";
import {LanguageChanger} from "../../components/languageChanger";
export const withNavigation = (Component: Component) => {
  return (props) => {
    return (
      <Component {...props} params={useParams()} navigate={useNavigate()} />
    );
  };
};

class AccountInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: {
        phoneCode: '374'
      },
      errors: {},
      apiErrorMsg: "",
      showError: false,
      signUp: null,
      countries: [],
      regions: [],
      states: [],
      schools: [],
      subjects: [],
      grades: [],
      lng: this.props.i18n.language === 'en' ? 2 : 1,
      genderOptions: [
        {
          value: "Female",
          label: this.props.t('female')
        },
        {
          value: "Male",
          label: this.props.t('male')
        },
      ]
    };
  }

  componentDidMount() {
    const body = localStorage.getItem("signUpForm");
    if (JSON.parse(body) !== null && JSON.parse(body) !== undefined) {
          this.setState({
              signUp: JSON.parse(body),
          });
          this.handleSelectOptions("countrie");
          this.handleSelectOptions("grade");
          this.handleSelectOptions("subject");

      } else {
          window.location.href = "/signup";
      }
  }

  componentDidUpdate() {
      this.updateOptions();
  }

  updateOptions() {
    if (this.props.i18n.language !== this.language) {
      const lng = this.props.i18n.language === 'en' ? 2 : 1
      this.handleSelectOptions('grade', lng)
      this.handleSelectOptions('subject', lng)
      this.handleSelectOptions('countrie', lng)
      this.handleSelectOptions("region", lng);

      this.setState((prevState) => {
        const updatedGenders = [
          {
            value: "Female",
            label: this.props.t('female'),
          },
          {
            value: "Male",
            label: this.props.t('male'),
          },
        ];
        const selectedGender = updatedGenders.find(el => el.value === this.state.fields.gender?.value);
        return {
          genderOptions: updatedGenders,
              fields: {
          ...prevState.fields,
                country: null,
                region: null,
                state: null,
                grade: null,
                subject: null,
                school: null,
                gender: selectedGender ? selectedGender : prevState.fields.gender
          }
        };
      });
      this.language = this.props.i18n.language;
    }
  }
  handleSelectOptions = (type) => {
    const lng = this.props.i18n.language === 'en' ? 2 : 1
    const { url, body } = constructSelectedItemApiParams(type, this.state.fields, lng);
    const handleItemsLength = (items) => {
      let fields = { ...this.state.fields };

      if (type === "state" && items.length === 1) {
        fields["state"] = {
          value: items[0].value,
          label: items[0].label,
        };
        this.setState({ fields }, () => {
          this.handleSelectOptions("school");
        });
      } else {
        if (type === "state") {
          fields["state"] = null;
        }
        if (type === "school") {
          fields["school"] = null;
        }
      }
      return items;
    };
    axios
        .post(url, body)
        .then((response) => extractItems(response))
        .then((items) => santizeSelectedResult(items))
        .then((items) => handleItemsLength(items))
        .then((items) => sortItemsByAlphabetical(items, type))
        .then((items) =>
            this.setState({
              [`${type}s`]: items,
            }, )
        )
        .catch(error => {
          console.error(`Error fetching ${type}:`, error);
        });
  };

  contactSubmit(e) {
    this.setState({errors: {}})
    this.setState({apiErrorMsg: ''})
    this.setState({ loading: true });
    e.preventDefault();
    const errors = checkValidationErrors(
      this.state.fields,
      this.state.signUp?.specialityId,
        this.props.t
    );
    if (errors) {
      this.setState({ loading: false, errors });
    } else {
      this.signUp();
    }
  }

  handleChange(field, e, el) {
    let fields = this.state.fields;
    if(field === 'phoneCode') {
      fields["phoneCode"] = el.dialCode;
    } else {
      fields[field] = e.target.value
    }
    this.setState({ fields });
  }

  signUp = () => {
    const { fields, signUp } = this.state;
    const lng = this.props.i18n.language === 'en' ? 2 : 1
    let body = new FormData();
    if(signUp.registrationMethod === "Phone") {
      body.append("Email", fields['email']);
    }
    if(signUp.registrationMethod === "Email") {
      body.append("PhoneNumberCountryCode", fields['phoneCode']);
      body.append("PhoneNumber", fields['phoneNumber']);
    }
    body.append("Gender", fields["gender"].value);
    body.append("BirthDate", new Date(fields["birthDate"]).toDateString());
    body.append("CountryId", fields["country"].value);
    body.append("SchoolRegionId", fields["region"].value);
    body.append("CityId", fields["state"].value);
    body.append("SchoolId", fields["school"]?.value || "");
    body.append("SchoolName", fields["school"]?.value ? "" : fields["school"]);
    body.append("SpecialityId", signUp.specialityId);
    body.append("LanguageId", lng);
    if(signUp?.specialityId == 4) {
      body.append("Occupation", fields['occupation'] || "");
    }
    if (signUp?.specialityId == 3 && fields["country"].value == 11) {
      body.append("GradeId", fields["grade"].value);
    }
    if (signUp?.specialityId == 1 &&  fields["country"].value == 11) {
      fields["subject"].map((item) => {
        body.append("subjectIds", item.value);
      });
    }
    axios
      .post(`${process.env.REACT_APP_IDENTITY_URL}/api/User/SignUpConfirmation`, body)
      .then(async (res) => {
        const errorState = {
          loading: false,
          showError: true,
        };

        if (res.data.accepted) {
          navigateToAccountPage(this.props.navigate, {countryId: this.state.fields['countryId']});
          return;
        } else {
          errorState.apiErrorMsg = getErrorMessage(res.data.errorMessages);
          this.setState(errorState);
        }
      });
  };

  render() {
    const {
      errors,
      fields,
      loading,
      showError,
      apiErrorMsg,
      signUp,
      states,
      countries,
      subjects,
      grades,
      regions,
      schools,
      genderOptions
    } = this.state;
    const { handleChange, contactSubmit, setState, handleSelectOptions } = this;
    const { t } = this.props
    return (
      <Account onlyBG>
        <div className='language-container'><LanguageChanger/></div>
        <div className="account-content">
          <h2 className="account-welcome">{t('welcome')}, {signUp?.firstName}</h2>
          <p className="account-fill">{t('letsCompleteAccount')}</p>
          <form className="account-form" onSubmit={contactSubmit.bind(this)}>
            {signUp?.registrationMethod === 'Email' ?
                <div
                    style={{marginBottom: "10px"}}
                    className={'phone-box'}>
                  <PhoneInput
                      inputProps={{
                        disabled: true,
                        style: { pointerEvents: 'none'}
                      }}
                      countryCodeEditable={false}
                      containerClass='phoneContainer'
                      inputClass={(errors["phone"] || apiErrorMsg) ?
                          "phoneInputError" :
                          "phoneInput"}
                      buttonClass='flagButton'
                      country={'am'}
                      value=""
                      onChange={handleChange.bind(this, "phoneCode")}
                  />
                  {apiErrorMsg === 'Հեռախոսահամարն արդեն գրանցված է։' ?
                      <span className={'error-text'}>{t('errors.usedPhone')} *</span> :
                      errors["phone"] ?
                      <span className={'error-text'}>{errors["phone"]} *</span> :
                  null}
                  <input
                      onInput={(e) => e.target.value =
                          fields['phoneCode'] == '374' ? e.target.value.slice(0, 8) :
                              e.target.value.slice(0, 15)}
                      value={fields['phoneNumber']}
                      type="number"
                      placeholder={fields['phoneCode'] == '374' ?
                          `${t('signInPhoneNumberPlaceholder')} *` :
                          `${t('phoneNumber')} *`}
                      onChange={handleChange.bind(this, "phoneNumber")}
                      className={(errors["phone"] || apiErrorMsg) ? 'phoneNumberInputError' : 'phoneNumberInput'}/>
                </div>
             :
                <div
                  style={{
                    width: "450px",
                    alignItems: 'flex-end',
                    display: 'flex',
                    flexDirection: 'column',
                    marginBottom: '10px'
                  }}>
                  <TextInput
                    hasError={errors["email"] || (apiErrorMsg === "Էլ. հասցեն արդեն գրանցված է։" && apiErrorMsg)}
                    placeholder={t('email')}
                    onChange={handleChange.bind (this, "email")}
                    value={fields["email"] || ""}
                  />
                </div>}
            <SelectBox
              hasError={errors["gender"]}
              placeholder={`${t('gender')} *`}
              options={genderOptions}
              onChange={(e) => {
                fields["gender"] = e;
                this.setState({ fields });
              }}
              value={fields["gender"] || ""}
            />
            <MyDatePicker
              hasError={errors["birthDate"]}
              placeholder={`${t('dateOfBirth')} *`}
              onChange={(e) => {
                fields["birthDate"] = e;
                this.setState({ fields });
              }}
              value={fields["birthDate"] || null}
            />
            <SelectBox
              hasError={errors["country"]}
              placeholder={`${t('country')} *`}
              options={countries}
              onChange={(e) => {
                fields["country"] = e;
                this.setState({ fields });
                handleSelectOptions("region");
              }}
              value={fields["country"] || ""}
            />
            <SelectBox
              hasError={errors["region"]}
              placeholder={`${t('region')} *`}
              options={regions}
              onChange={(e) => {
                fields["region"] = e;
                this.setState({ fields });
                handleSelectOptions("state");
              }}
              value={fields["region"] || ""}
            />
            {states.length > 1 && (
              <SelectBox
                hasError={errors["state"]}
                placeholder={`${t('commune')} *`}
                options={states}
                onChange={(e) => {
                  fields["state"] = e;
                  this.setState({ fields });
                  handleSelectOptions("school");
                }}
                value={fields["state"] || ""}
              />
            )}
            {signUp?.specialityId != 4 &&
                (fields['country']?.label === 'Հայաստան' && schools.length ?
                <SelectBox
                hasError={errors["school"]}
                placeholder={`${t('school')} *`}
                options={schools}
                onChange={(e) => {
                  fields["school"] = e;
                  this.setState ({fields});
                }}
                value={fields["school"] || ""}
            /> :
                <TextInput
                    style={{marginBottom: '10px'}}
                    hasError={errors["school"]}
                    placeholder={`${t('school')} *`}
                    onChange={handleChange.bind (this, "school")}
                    value={fields["school"] || ""}
                />)
            }
            {signUp?.specialityId == 3 &&
             fields['country']?.label === 'Հայաստան' &&
              <SelectBox
                hasError={errors["grade"]}
                placeholder={`${t('class')} *`}
                options={grades}
                onChange={(e) => {
                  fields["grade"] = e;
                  this.setState({ fields });
                }}
                value={fields["grade"] || ""}
              />}
            {signUp?.specialityId == 1 &&
             fields['country']?.label === 'Հայաստան' &&
              <SelectBox
                hasError={errors["subject"]}
                placeholder={`${t('subject')} *`}
                options={subjects}
                className={'subjects account-input'}
                isMulti
                onChange={(e) => {
                  fields["subject"] = e;
                  this.setState({ fields });
                }}
                value={fields["subject"] || ""}
              />
            }
            {signUp?.specialityId == 4 && (
              <TextInput
                hasError={errors["occupation"]}
                placeholder={`${t('activity')} *`}
                onChange={handleChange.bind (this, "occupation")}
                value={fields["occupation"] || ""}
              />
            )}
            <Button
              className={"account-submit green"}
              text={t('save')}
              action={contactSubmit.bind(this)}
            />
          </form>
          {loading && <Loader />}
        </div>
      </Account>
    );
  }
}

export default withTranslation()(withNavigation(AccountInfo))
