import axios from "axios";

export const getUserUpdateApiParams = (fields, user, uploadedFile) => {
  let body = new FormData();

  body.append("firstName", fields["firstName"]);
  body.append("lastName", fields["lastName"]);
  body.append("specialityId", fields["speciality"].value);
  body.append("gender", fields["gender"].value);
  body.append("birthDate", new Date(fields["birthDate"]).toLocaleString());
  body.append("schoolRegionId", fields["region"].value);
  body.append("phoneNumber", fields["phone"]);
  body.append('countryId', fields.country.value)
  body.append('cityId', fields.state.value)

  if(fields.speciality.text !== 'Other' && fields.country.value !== 11) {
    body.append("SchoolName", fields["school"]);
  }
  if(fields.speciality.text !== 'Other' && fields.country.value === 11) {
    body.append("schoolId", fields["school"].value);
  }
  if(fields.speciality.text === 'Other') {
    body.append("occupation", fields["occupation"]);
  }
  if (fields.speciality.text === "Student") {
    body.append("gradeId", fields?.grade?.value || '');
  }
  if (fields.speciality.text === "Teacher") {
    if (fields["subject"].length > 0) {
      fields["subject"].map((item) => {
        body.append("subjectIds", item.value);
      });
    }
  }
  if (uploadedFile.url !== null) {
    body.append("avatar", uploadedFile.url);
    body.append("mediaId", uploadedFile.id);
  } else {
    if (fields.avatar !== null) {
      body.append("avatar", fields.avatar);
    } else {
      body.append("avatar", "");
    }
    if (fields.mediaId !== null) {
      body.append("mediaId", fields.mediaId);
    } else {
      body.append("mediaId", "");
    }
  }

  return body;
};

export const constructSelectedItemApiParams = (type, fields, lng) => {
  const obj = {
    countrie: {
      url: `${process.env.REACT_APP_DICTIONARY_URL}/api/SchoolCountry/DataProvider/${lng}`,
      body: {
        page: 1,
        perPage: 1500,
        sort: [
          {
            field: "title",
            type: "asc",
          },
        ],
        columnFilters: {
          // status: "Active",
          // title: "Հայաստան",
        },
      },
    },
    region: {
      url: `${process.env.REACT_APP_DICTIONARY_URL}/api/State/DataProvider/${lng}`,
      body: {
        page: 1,
        perPage: 1500,
        sort: [
          {
            field: "title",
            type: "asc",
          },
        ],
        columnFilters: {
          status: "Active",
          "state.country.id": fields["country"]
              ? String(fields["country"].value)
              : null,
        },
      },
    },
    state: {
      url: `${process.env.REACT_APP_DICTIONARY_URL}/api/City/DataProvider/${lng}`,
      body: {
        page: 1,
        perPage: 1500,
        sort: [
          {
            field: "title",
            type: "asc",
          },
        ],
        columnFilters: {
          status: "Active",
          "state.id": fields["region"]
              ? String(fields["region"].value)
              : null,
        },
      },
    },
    school: {
      url: `${process.env.REACT_APP_DICTIONARY_URL}/api/School/DataProvider/${lng}`,
      body: {
        page: 1,
        perPage: 1500,
        sort: [
          {
            field: "title",
            type: "asc",
          },
        ],
        columnFilters: {
          status: "Active",
          "city.id": fields["state"]
            ? String(fields["state"].value)
            : null,
        },
      },
    },
    subject: {
      url: `${process.env.REACT_APP_DICTIONARY_URL}/api/Subject/DataProvider/${lng}`,
      body: {
        page: 1,
        perPage: 100,
        sort: [
          {
            field: "title",
            type: "asc",
          },
        ],
        columnFilters: {
          status: "Active",
        },
      },
    },
    grade: {
      url: `${process.env.REACT_APP_DICTIONARY_URL}/api/Grade/DataProvider/${lng}`,
      body: {
        page: 1,
        perPage: 100,
        sort: [
          {
            field: "title",
            type: "asc",
          },
        ],
        columnFilters: {
          status: "Active",
        },
      },
    },
  };

  return obj[type];
};

export const extractItems = (res) => {
  const { data } = res;
  if (data.accepted) {
    if (data.data && data.data[0]) {
      return data.data[0].items;
    }
  }
  return [];
};

export const santizeSelectedResult = (items) =>
    items.map((value) => ({
      value: value.id,
      label: value.title,
    }));

export const sortItemsByAlphabetical = (items) =>
    items.sort((a, b) =>
        a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1
    );

export const getCurrentUserData = (url) => axios.get(url);

export const updateUserAccount = (url, body) => axios.put(url, body);

export const changeUserPassword = (url, body) => axios.put(url, body);

export const getGradeByID = (id) =>
    axios.get(
        `${process.env.REACT_APP_DICTIONARY_URL}/api/Grade/${id}/Language/1`
    );

export const getSubjectByID = (id) =>
    axios.get(
        `${process.env.REACT_APP_DICTIONARY_URL}/api/Subject/${id}`
    );

export const getSchoolByID = (id) =>
    axios.get(
        `${process.env.REACT_APP_DICTIONARY_URL}/api/School/${id}/Language/1`
    );
